import React, { Component } from "react";
import { Link } from "gatsby";
import logo from "../images/logo.png";

class Header extends Component {
  componentDidMount() {
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  }

  render() {
    return (
          <nav className="navbar is-transparent navbar-border">
            <div className="container">
              <div className="navbar-brand">
                <Link to="/" className="navbar-item" title="DEVSPARK">
                  <img src={logo} alt="DEVSPARK"/>
                  <p className="is-size-5 has-text-weight-semibold logo-text">
                    DEVSPARK
                  </p>
                </Link>
                <div className="navbar-burger burger" data-target="navMenu">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <div id="navMenu" className="navbar-menu container">
                <div className="navbar-start">
                  <Link className="navbar-item" to="/who-we-are">Company</Link>
                  <Link className="navbar-item" to="/why-devspark">Why Devspark</Link> 
                  <Link className="navbar-item" to="/solutions">Solutions</Link> 
                  <Link className="navbar-item" to="/portfolio">Portfolio</Link> 
                  <Link className="navbar-item" to="/top-careers-software-developers">Career</Link> 
                  <Link className="navbar-item" to="/blog/news">Blog</Link> 
                  <Link className="navbar-item" to="/contact-us">Contact us</Link> 
                </div>
                <div className="navbar-end">
                  <i className="navbar-item material-icons is-hidden">search</i>
                </div>
              </div>
            </div>
          </nav>
    );
  }
}

export default Header;
