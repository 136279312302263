import React from "react"

const Footer = () => {
  return (
    <footer className="content has-text-centered">
            © {new Date().getFullYear()}, DEVSPARK
    </footer>
  )
}

export default Footer
