import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const CardLink = ({title, subtitle, featuredImage, url, hideSubtitle, excerpt}) => (
        <>
            <Link to={url} className="card-item">
                <div className="image card-margin-15">
                    <Img fluid={ featuredImage ? featuredImage.childImageSharp.fluid : {} }/>
                </div>
                <div className="has-text-left">
                    <p className="is-size-6 has-text-weight-semibold">{title}</p>
                    <p className={'is-size-7 is-uppercase' + (hideSubtitle ? '' : ' is-hidden')}>{subtitle}</p>
                </div>
            </Link>
            <div className={'has-text-left' + (excerpt ? '' : ' is-hidden')}>
                <p>{excerpt}</p>
            </div>
        </>
    )

export default CardLink;